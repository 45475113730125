*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 100%;
}

body {
  background-color: #efefef;
}

/* CONTAINER STYLES */
.App {
  width: 75vw;
  height: auto;
  margin: 2rem auto;
  padding: 1rem;
  /* border: dashed 1px dodgerblue; */
  border-radius: 5px;
}

.app-title {
  font-family: Poppins, sans-serif;
  font-size: 2.5rem;
  font-weight: 400;
  text-align: center;
  margin-bottom: 3rem;
  color: dodgerblue;
}

/* CREATE TODO FORM STYLES */

/* create wrapper */
.create-wrapper {
  width: 100%;
  margin: 0 auto;
}

/* create form styles */
.create-form {
  width: 100%;
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* text input styles */
.form-text {
  display: block;
  width: 74%;
  padding: 0.5rem;
  border: solid 1px #aaa;
  border-radius: 5px;
  outline-width: 1px;
  font-family: Roboto, sans-serif;
}

.form-text:focus {
  outline-color: dodgerblue;
}

/* form button styles */
.form-button {
  display: block;
  width: 24%;
  padding: 0.6rem;
  background-color: #1e90ff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-family: Roboto, sans-serif;
  transition: all 200ms ease-in-out;
}

.form-button:hover {
  background-color: #0072dd;
}

/* TODO ITEMS STYLES */
/* empty todos message styles */
.empty-msg {
  font-family: Roboto, sans-serif;
  font-size: 1rem;
  color: #777;
}

/* items wrapper */
.items-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 1rem;
}

/* todo item container */
.todo-item {
  width: 100%;
  background-color: white;
  padding: 0.6rem 1rem;
  border-radius: 5px;
  font-family: Roboto, sans-serif;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 1rem;
}

/* todo item delete button styles */
.todo-delete {
  padding: 0.5rem;
  background-color: #ff397d;
  border: none;
  border-radius: 5px;
  transition: all 200ms ease-in-out;
  color: white;
  cursor: pointer;
}

.todo-delete:hover {
  background-color: #da0061;
}

/* change the width in big screens */
@media (min-width: 900px) {
  .App {
    width: 50vw;
  }

  .app-title {
    font-size: 3.5rem;
  }
}
